import React, { useState } from "react"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"
import "./stripform.css"
import CloseIcon from "@material-ui/icons/Close"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import delete_icon from "../../../images/delete_black.svg"
import { useSelector, useDispatch } from "react-redux"
import {
  updatePaymentCard,
  deletePaymentCard,
} from "../../../redux/slices/subscriptionModalSlice"
import {
  makeStyles,
  Typography,
  IconButton,
  InputLabel,
} from "@material-ui/core"
import { addPaymentCard } from "../../../redux/slices/subscriptionModalSlice"
import Loader from "../Loader/Loader"
import DrawerSelect from "../../Schedule/Drawer/Utils/DrawerSelect"
import { VAT_TYPES } from "../../../utils/constants"

const useStyles = makeStyles(theme => ({
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  editRootButton: {
    display: "flex",
    width: "97%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    bottom: "0",
    position: "absolute",
    marginBottom: "20px",
    overflowY: "hidden",
  },
  labelStyle: {
    color: "#0A3133",
    marginTop: "10px",
  },
}))

const PaymentForm = ({ isEdit, onClose }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const stripe = useStripe()

  const elements = useElements()

  const userState = useSelector(state => state.userSlice?.user)
  const loading = useSelector(state => state.subscriptionModalSlice.loading)

  const [billingDetail, setBillingDetail] = useState({
    name: "",
    address: "",
    vatNumber: "",
    vatType: "",
  })

  const onChange = e => {
    setBillingDetail({ ...billingDetail, [e.target.name]: e.target.value })
  }

  const handleDelete = () => {
    dispatch(deletePaymentCard())
  }

  const handlePayment = async event => {
    try {
      event.preventDefault()

      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        card: elements.getElement(CardExpiryElement),
        card: elements.getElement(CardCvcElement),
        billing_details: {
          name: userState.displayName || billingDetail.name,
          email: userState.email || "",
          address: {
            city: null,
            country: null,
            line1: billingDetail.address,
            line2: null,
            postal_code: "54545",
            state: null,
          },
        },
      })

      isEdit
        ? dispatch(updatePaymentCard({ ...paymentMethod, ...billingDetail }))
        : dispatch(addPaymentCard({ ...paymentMethod, ...billingDetail }))

      onClose()
    } catch (err) {
      console.log("err0000-", err)
    }
  }

  return (
    <form style={{ height: "90%" }} onSubmit={handlePayment}>
      <div
        style={{
          marginTop: "40px ",
          padding: "0px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
            {isEdit ? "Edit Card Detail" : "Add Card Detail"}
          </Typography>
          <IconButton sx={{ borderRadius: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <InputLabel shrink className={classes.labelStyle}>
          Name
        </InputLabel>
        <input
          name="name"
          placeholder="name"
          className={"rowPaymentInput"}
          style={{ width: "85%" }}
          onChange={e => onChange(e)}
        />
        <InputLabel shrink className={classes.labelStyle}>
          Address
        </InputLabel>
        <input
          name="address"
          style={{ width: "85%" }}
          placeholder="address"
          className={"rowPaymentInput"}
          onChange={e => onChange(e)}
        />

        {/* <div style={{ width: "95%" }}>
          <DrawerSelect
            data={VAT_TYPES}
            onChange={value =>
              setBillingDetail({ ...billingDetail, vatType: value.value })
            }
            value={VAT_TYPES.find(item => item.value === billingDetail.vatType)}
            placeHolder="VAT Type"
            label="VAT Type"
          />
        </div> */}

        {/* <InputLabel shrink className={classes.labelStyle}>
          VAT Number
        </InputLabel>
        <input
          name="vatNumber"
          style={{ width: "85%" }}
          placeholder="VAT Number"
          className={"rowPaymentInput"}
          onChange={e => onChange(e)}
        /> */}
        <InputLabel shrink className={classes.labelStyle}>
          Card Number
        </InputLabel>
        <CardNumberElement className={"rowPaymentInputFullWidth"} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "20px", width: "40%" }}>
            <InputLabel shrink className={classes.labelStyle}>
              CVC
            </InputLabel>
            <CardCvcElement className={"rowPaymentInput"} />
          </div>

          <div style={{ width: "40%", marginRight: "25px" }}>
            <InputLabel shrink className={classes.labelStyle}>
              Exp
            </InputLabel>
            <CardExpiryElement className={"rowPaymentInput"} />
          </div>
        </div>
      </div>
      {!loading && (
        <>
          {isEdit ? (
            <div className={classes.editRootButton}>
              <div>
                <button type="submit">{"Update"}</button>
              </div>
              <div
                onClick={handleDelete}
                style={{ width: "20%", cursor: "pointer" }}
              >
                <img src={delete_icon} style={{ width: "60px" }} alt="" />
              </div>
            </div>
          ) : (
            <div className={"btnContainer"}>
              <button className="button" type="submit">
                {"Submit"}
              </button>
            </div>
          )}
        </>
      )}

      {loading && <Loader />}
    </form>
  )
}
export default PaymentForm
